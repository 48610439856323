<template>
  <v-app id="test">
    <link href="https://fonts.googleapis.com/css?family=Material+Icons" rel="stylesheet">

    <v-content>
      <section id="hero">
        <v-row no-gutters>
          <v-img
              :min-height="'calc(50vh - ' + $vuetify.application.top + 'px)'"
              src="./assets/IMG_1329.jpg"
          >
            <v-theme-provider dark>
              <v-container fill-height>
                <v-row
                    align="center"
                    class="white--text mx-auto"
                    justify="center"
                >
                  <v-col
                      class="white--text text-center"
                      cols="12"
                      tag="h1"
                  >
                    <span
                        :class="[$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2']"
                        class="font-weight-light"
                    >
                      WELCOME TO THE
                    </span>

                    <br>

                    <span
                        :class="[$vuetify.breakpoint.smAndDown ? 'display-3': 'display-4']"
                        class="font-weight-black"
                    >
                      Air Quality Website <br> <br>
                    </span>

                  </v-col>
                </v-row>
              </v-container>
            </v-theme-provider>
          </v-img>
        </v-row>
      </section>

      <section id="about-me">
        <v-container class="text-center">
          <h2 class="display-3 font-weight-bold mb-3">ABOUT</h2>

          <v-responsive
              class="mx-auto mb-8"
              width="56"
          >
            <v-divider class="mb-1"></v-divider>

            <v-divider></v-divider>
          </v-responsive>

          <v-responsive
              class="mx-auto title font-weight-light mb-8"
              max-width="1000"
          >
            The COVID-19 pandemic has increased the awareness of how important proper ventilation is.
            To continuously monitor the ventilation rate in our offices and meeting rooms, it was decided to place a CO2 monitor in every office and meeting space.
            The CO2 monitors deployed in the Beacon are custom-built using our Octa platform.
          </v-responsive>
          <v-responsive
              class="mx-auto title font-weight-light mb-8"
              max-width="1000"
          >
            <v-btn
                color="info"
                elevation="2"
                x-large
                href="https://aq-dashboard.idlab.uantwerpen.be/"
                target="_blank"

            >Airquality Dashboard</v-btn>
          </v-responsive>

        </v-container>
      </section>
      <section id="why-measering" class="grey lighten-3">
        <v-container class="text-center">
          <h2 class="display-2 font-weight-bold mb-3">Why measure CO2</h2>

          <v-responsive
              class="mx-auto mb-8"
              width="56"
          >
            <v-divider class="mb-1"></v-divider>

            <v-divider></v-divider>
          </v-responsive>

          <v-responsive
              class="mx-auto title font-weight-light mb-8"
              max-width="1000"
          >
            The CO2 concentration is a good indication of how well a room is ventilated. Outdoor air contains a CO2 concentration of roughly 400 ppm (parts per million). Humans exhale CO2, so when people are present indoors, the CO2 concentration in a room increases, depending on the size of the room, the number of occupants, the duration, and the rate of ventilation.
            Therefore, CO2 is often used as an indicator for indoor air quality and as an indicator for the rate of ventilation in occupied spaces.

          </v-responsive>
        </v-container>
      </section>
      <section id="infection-risk" >
        <v-container class="text-center">
          <h2 class="display-2 font-weight-bold mb-3">CO2 and infection risk</h2>

          <v-responsive
              class="mx-auto mb-8"
              width="56"
          >
            <v-divider class="mb-1"></v-divider>

            <v-divider></v-divider>
          </v-responsive>

          <v-responsive
              class="mx-auto title font-weight-light mb-8"
              max-width="1000"
          >
            Modelling shows that the risk of infections increases as the CO2 concentration rises in a room where an infected person is present. The risk of airborne transmission also depends on the amount of infected persons in a room, and the time spent in that room.
            It is important to note that there is no CO2 concentration that indicates zero risk of infection. Even when the CO2 concentration is close to outdoor levels, a risk still exists.
            <br><br>
            Taking into account the recommendations from experts, the Flemish Agentschap Zorg en Gezondheid advises a maximum CO2 concentration of <strong>900 ppm</strong>.
          </v-responsive>
        </v-container>
      </section>
      <section
          id="features"
          class="grey lighten-3"
      >
        <div class="py-12"></div>

        <v-container class="text-center">
          <h2 class="display-2 font-weight-bold mb-3">CO2 sensors in the Beacon</h2>
          <v-responsive
              class="mx-auto title font-weight-light mb-8"
              max-width="1000"
          >
            The CO2 sensors in the Beacon give a local indication of the CO2 concentration using an onboard LED. With a maximum CO2 concentration of 900 ppm, these meters use the following thresholds:

          </v-responsive>
          <v-responsive
              class="mx-auto mb-12"
              width="56"
              max-width="1000"
          >
            <v-divider class="mb-1"></v-divider>

            <v-divider></v-divider>
          </v-responsive>
          <v-responsive
              class="mx-auto mb-12"
              max-width="1200"
          >
            <v-row>
              <v-col
                  v-for="({ icon, color, title, text }, i) in features"
                  :key="i"
                  cols="12"
                  md="4"
              >
                <v-card
                    class="py-12 px-4"
                    color="grey lighten-5"
                    flat
                >
                  <v-theme-provider dark>
                    <div>
                      <v-icon
                          size="88"
                          :color="color"
                          v-text="icon"
                      ></v-icon>
                    </div>
                  </v-theme-provider>

                  <v-card-title
                      class="justify-center font-weight-black text-uppercase"
                      v-text="title"
                  ></v-card-title>
                  <v-card-text
                      class="subtitle-1"
                      v-text="text"
                  ></v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-responsive>

          <v-responsive
              class="mx-auto mb-12"
              max-width="1000"
          >

          <div class="py-1"></div>
          <div class="py-3">
            It is advised to always stay in the green zone, if possible.
          </div>
          <div class="py-3">
            An orange LED indicates the CO2 concentration is still within the recommended limit, but it is approaching this limit. When you see the LED turning from green to orange, you might already want to proactively open some windows and/or a door to increase the ventilation rate in the room.
          </div>
          <div class="py-3">
            A red LED indicates the CO2 concentration is above the recommended limit. This does not mean the situation is immediately unsafe. Concentrations of more than 900 ppm should be avoided, however, so open some windows and/or a door to increase the ventilation rate in the room.
          </div>
          <div class="py-3">
            As stated before, zero risk of infection does not exist. The sensors measure CO2, which is an indication of how well the room is ventilated, but it is not a COVID safety indicator! Keep respecting the rules at all times, regardless of the indications on these CO2 meters!
          </div>
          </v-responsive>

        </v-container>
      </section>
    </v-content>

    <v-footer
        class="justify-center"
        color="#292929"
        height="50"
    >
      <div class="title font-weight-light grey--text text--lighten-1 text-center">
        &copy; {{ (new Date()).getFullYear() }} — IDLab, imec
      </div>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  created () {
    document.title = "Air Quality";
  },
  data: () => ({
    small_image: "./assets/par1.png",
    features: [
      {
        icon: 'brightness_1',
        color: 'green',
        title: 'Less than 800 ppm',
        text: 'Space is well ventilated'
      },
      {
        icon: 'brightness_1',
        color: 'orange',
        title: 'Between 800 and 1200 ppm',
        text: 'Opening a window/door is recommended'
      },
      {
        icon: 'brightness_1',
        color: 'red',
        title: 'More than 1200 ppm',
        text: 'Opening a window/door is mandatory'
      },
    ],
  }),
};
</script>
<style>

</style>


