import Vue from 'vue'
import App from './App.vue'
//import Template from './Template.vue'
import '@mdi/js';
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

new Vue({
  vuetify,
  icons: {
    iconfont: 'mdiSvg',
  },
  render: h => h(App)
}).$mount('#app')
